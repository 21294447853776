<template>
  <div class="c1">
    <canvas id="draw"></canvas>
    <h1 class="title">{{ greeting }}<span class="highlight">.</span></h1>
  </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { onBeforeUnmount, onMounted, ref } from 'vue';

export default {
  name: 'HeroSection',
  setup() {
    const greeting = ref('');
    let timeInterval;

    const getCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    };

    const greetings = {
      morning: [
        `${getCurrentTime()}\n아침부터 일`,
        `${getCurrentTime()}\n하루의 시작`,
        '잘자셨나요?',
        '일찍 일어나는 새가\n버그를 잡는다'
      ],
      lunch: [
        `${getCurrentTime()}\n점심시간`,
        'BREAK TIME\nRESTART SOON',
        `점심도 못먹고\n일하는 중`,
        'LUNCH TIME\nBUG FIXING'
      ],
      afternoon: [
        `${getCurrentTime()}\n오후의 코딩`,
        '점심은 드셨나요?\n벌써 오후네요',
        '벌써 하루의 절반이\n지나가버렸다',
        `${getCurrentTime()}\n개발 진행중`
      ],
      evening: [
        `${getCurrentTime()}\n여전히 일하는중`,
        '좋은 저녁\n보내세요',
        `오늘 하루\n수고하셨어요`,
        `${getCurrentTime()}\n좋은 저녁`,
      ],
      night: [
        `${getCurrentTime()}\n심야 코딩`,
        'MIDNIGHT\nDEBUGGING',
        `${getCurrentTime()}\n코드는 계속된다`,
        'NIGHT MODE\nON'
      ]
    };

    const getRandomGreeting = (timeOfDay) => {
      const greetingList = greetings[timeOfDay];
      const randomIndex = Math.floor(Math.random() * greetingList.length);
      return greetingList[randomIndex];
    };

    const getGreeting = () => {
      const hour = new Date().getHours();
      
      if (hour >= 5 && hour < 12) {
        return getRandomGreeting('morning');
      } else if (hour >= 12 && hour < 14) {
        return getRandomGreeting('lunch');
      } else if (hour >= 14 && hour < 17) {
        return getRandomGreeting('afternoon');
      } else if (hour >= 17 && hour < 21) {
        return getRandomGreeting('evening');
      } else {
        return getRandomGreeting('night');
      }
    };

    const updateTime = () => {
      const currentTime = getCurrentTime();
      greeting.value = greeting.value.replace(/\d{2}:\d{2}/, currentTime);
    };

    onMounted(() => {
      greeting.value = getGreeting();
      init();
      // 1분마다 시간 업데이트
      timeInterval = setInterval(updateTime, 60000);

      // 모델 로드
      const loader = new GLTFLoader();
      loader.load('/csm.gltf', 
        (gltf) => {
          model = gltf.scene;
          
          model.traverse((node) => {
            if (node.isMesh) {
              node.material.side = THREE.FrontSide;
              node.frustumCulled = true;
            }
          });
          
          scene.add(model);
          animate();
        },
        (xhr) => {
          console.log(`모델 로딩 진행률: ${(xhr.loaded / xhr.total * 100)}%`);
        },
        (error) => {
          console.error('GLTFLoader 에러:', error);
        }
      );

      window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      if (timeInterval) {
        clearInterval(timeInterval);
      }
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
      
      if (model) {
        scene.remove(model);
        model.traverse((node) => {
          if (node.isMesh) {
            node.geometry.dispose();
            node.material.dispose();
          }
        });
      }
      
      renderer.dispose();
      controls.dispose();
    });

    let scene, camera, renderer, controls, model;
    let animationFrameId;

    // 리사이즈 핸들러
    const handleResize = () => {
      const width = window.innerWidth/3;
      const height = window.innerHeight/3;
      
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    const init = () => {
      scene = new THREE.Scene();
      const canvas = document.querySelector('#draw');
      
      renderer = new THREE.WebGLRenderer({
        canvas,
        antialias: true,
        alpha: true,
        powerPreference: 'high-performance'
      });
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2)); // 픽셀 비율 제한
      renderer.setSize(window.innerWidth, window.innerHeight);
      
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, 0, 20);
      
      const light = new THREE.DirectionalLight(0xffffff, 4);
      light.position.set(10, 10, 10);
      scene.add(light);

      controls = new OrbitControls(camera, renderer.domElement);
      controls.enableZoom = false;
      controls.enablePan = false;
      controls.minDistance = 10;
      controls.enableDamping = true; // 부드러운 컨트롤
      controls.dampingFactor = 0.05;
      controls.update();
    };
    
    const animate = () => {
      animationFrameId = requestAnimationFrame(animate);
      
      if (model) {
        model.rotation.y += 0.005;
      }
      controls.update();
      renderer.render(scene, camera);
    };

    return {
      greeting
    };
  }
}
</script>

<style scoped>
  .c1 {
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex-direction: column;
    background: #f7f3f0;
    color: #fff;
    position: relative;
    align-content: center;
    flex-wrap: nowrap;
  }
  .c1 .title {
    font-family: 'Pretendard', sans-serif;
    font-weight: 900;
    line-height: 1.2;
    font-size: 12em;
    color: #000089;
    position: relative;
    z-index: 2;
    margin-left: 1em;
    transition: all 0.5s ease;
    white-space: pre-line; /* 줄바꿈 처리를 위해 추가 */
  }
  
  .c1 .subtitle {
    font-size: 3em;
  }
  
  .c1 .title .highlight {
    color: #f0771f;
    display: inline-block;
    transform: translateY(-0.1em); /* 점(.) 위치 미세 조정 */
  }
  #draw {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 768px) {
    .c1 {
      height: 70vh;
    }

    .c1 .title {
      font-size: 7em;
      margin-left: 0.5em;
      text-align: center;
      line-height: 1.2;
    }
  }

  @media (max-width: 480px) {
    .c1 .title {
      font-size: 5em;
      line-height: 1.3;
    }
  }
</style>