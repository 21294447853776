<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-section main-section">
        <h2>C#M<span class="highlight">.</span></h2>
        <p class="slogan">코드 위의 마에스트로</p>
      </div>
      <div class="footer-sections-wrapper">
        <div class="footer-section">
          <h3>Contact<span class="highlight">.</span></h3>
          <div class="contact-links">
            <a 
              v-for="link in contactLinks" 
              :key="link.label"
              :href="link.href" 
              class="contact-item button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="label">{{ link.label }}</span>
              <span class="value">{{ link.value }}</span>
            </a>
          </div>
        </div>
        <div class="footer-section">
          <h3>Social<span class="highlight">.</span></h3>
          <div class="social-links">
            <a 
              v-for="link in socialLinks" 
              :key="link.label"
              :href="link.href" 
              class="social-item button"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="label">{{ link.label }}</span>
              <span class="value">{{ link.value }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <p>&copy; {{ currentYear }} wello-X. All rights reserved.</p>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  width: 100%;
  background-color: #f7f3f0;
  padding: 8em 0 0;
  overflow-x: hidden;
}

.footer-content {
  width: 83%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 6em;
  padding: 0 2em;
  box-sizing: border-box;
}

.main-section h2 {
  font-family: 'Pretendard', sans-serif;
  font-size: 6em;
  font-weight: 900;
  color: #000089;
  margin: 0;
}
.footer-btn {
    color: #000089;
    font-size: 2.3em;
    font-weight: 400;
    padding: 0.3em 0.8em;
    border-radius: 0.7em;
    border: 0.15em solid #000089;
    transition: background-color 0.3s, color 0.3s;
    margin: 0.3em;
  }
  
  .footer-btn:hover {
    background-color: #000089;
    color: #ffffff;
  }

.main-section .slogan {
  font-size: 2em;
  color: #000089;
  opacity: 0.8;
  margin-top: 0.5em;
}

.footer-sections-wrapper {
  display: flex;
  gap: 6em;
}

.footer-section {
  flex: 1;
}

.footer-section h3 {
  font-family: 'Pretendard', sans-serif;
  font-size: 3em;
  margin-bottom: 1em;
  font-weight: 900;
  color: #000089;
}

.highlight {
  color: #f0771f;
}

.contact-links, .social-links {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.contact-item, .social-item {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  text-decoration: none;
  transition: all 0.2s ease;
  padding: 1em;
  border-radius: 8px;
  background-color: rgba(0, 0, 137, 0.05);
}

.contact-item:hover, .social-item:hover {
  transform: translateX(10px);
  background-color: rgba(0, 0, 137, 0.1);
}

.label {
  font-family: 'Pretendard', sans-serif;
  font-size: 1.6em;
  color: #000089;
  opacity: 0.6;
  font-weight: 500;
}

.value {
  font-family: 'Pretendard', sans-serif;
  font-size: 2em;
  color: #000089;
  font-weight: 600;
}

.footer-bottom {
  margin-top: 8em;
  padding: 2em;
  border-top: 1px solid rgba(0, 0, 137, 0.1);
  text-align: center;
}

.footer-bottom p {
  font-family: 'Pretendard', sans-serif;
  font-size: 1.6em;
  color: #000089;
  opacity: 0.6;
}

@media (max-width: 1024px) {
  .footer-content {
    flex-direction: column;
    gap: 4em;
  }
  
  .footer-sections-wrapper {
    flex-direction: column;
    gap: 4em;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 4em 0 0;
  }

  .footer-content {
    width: 90%;
    gap: 3em;
  }

  .main-section h2 {
    font-size: 4em;
  }

  .footer-section h3 {
    font-size: 2.5em;
  }

  .label {
    font-size: 1.4em;
  }

  .value {
    font-size: 1.8em;
  }

  .footer-bottom {
    margin-top: 4em;
  }
}
</style> 

<script setup>
import { computed } from 'vue'

// 연도를 computed 속성으로 변경
const currentYear = computed(() => new Date().getFullYear())

// 링크 데이터를 반응형 객체로 관리
const contactLinks = [
  {
    label: 'Email',
    value: 'me@mua.co.kr',
    href: 'mailto:me@mua.co.kr'
  },
  {
    label: 'Discord',
    value: 'WelloCord →',
    href: 'https://discord.gg/H9N78BKyAT'
  }
]

const socialLinks = [
  {
    label: 'GitHub',
    value: 'WelloStudio →',
    href: 'https://github.com/wello-studios'
  },
  {
    label: 'Youtube',
    value: '절대 누르지 마',
    href: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'
  }
]
</script> 