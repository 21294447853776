<template>
  <header class="header" id="header">
    <h1 class="logo">C#M</h1>
    
    <!-- 모바일 메뉴 토글 버튼 -->
    <button class="menu-toggle" @click="toggleMenu" :class="{ 'active': isMenuOpen }">
      <span></span>
      <span></span>
      <span></span>
    </button>

    <!-- 네비게이션 메뉴 -->
    <nav class="hnav" :class="{ 'active': isMenuOpen }">
      <ul>
        <li>
          <a 
            v-for="item in menuItems" 
            :key="item.text"
            :href="item.link" 
            class="header-btn"
            @click="closeMenu"
          >
            {{ item.text }}
          </a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'SiteHeader',
  data() {
    return {
      isMenuOpen: false,
      menuItems: [
        { text: '팀', link: '#us' },
        { text: '프로젝트', link: '#project' },
        { text: 'WELLO', link: 'https://wello.csm.kr' },
        { text: '지원 →', link: 'https://discord.gg/kBHKgXhUwj' }
      ]
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      document.body.style.overflow = this.isMenuOpen ? 'hidden' : '';
    },
    closeMenu() {
      this.isMenuOpen = false;
      document.body.style.overflow = '';
    }
  },
  // beforeDestroy 대신 beforeUnmount 사용
  beforeUnmount() {
    document.body.style.overflow = '';
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 10em;
  right: 10em;
  top: 2em;
  height: 8em;
  border-radius: 3.5em;
  z-index: 1000;
  background-color: rgba(247, 243, 240, 0.9);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4em;
  box-sizing: border-box;
}

.logo {
  font-size: 4em;
  font-weight: bold;
  color: #000089;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 3em;
  height: 2em;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}

.menu-toggle span {
  width: 100%;
  height: 2px;
  background-color: #000089;
  transition: all 0.3s ease;
}

.hnav ul {
  list-style: none;
  display: flex;
  gap: 2em;
  margin: 0;
  padding: 0;
}

.header-btn {
  color: #000089;
  font-size: 2.3em;
  font-weight: 400;
  padding: 0.3em 0.8em;
  border-radius: 0.7em;
  border: 0.15em solid #000089;
  transition: background-color 0.3s, color 0.3s;
  margin: 0.3em;
  text-decoration: none;
}

.header-btn:hover {
  background-color: #000089;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .header {
    left: 5em;
    right: 5em;
  }
}

@media (max-width: 768px) {
  .header {
    left: 2em;
    right: 2em;
    padding: 0 2em;
  }

  .menu-toggle {
    display: flex;
  }

  .hnav {
    position: fixed;
    top: 12em;
    left: 50% !important;
    transform: translateX(-50%);
    width: calc(100% - 4em);
    background-color: rgba(247, 243, 240, 0.98);
    backdrop-filter: blur(10px);
    border-radius: 3.5em;
    padding: 2em;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }

  .hnav.active {
    transform: translateX(-50%);
    opacity: 1;
    visibility: visible;
  }

  .hnav ul {
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 2.5em;
  }

  .hnav ul li {
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }

  .header-btn {
    margin: 0;
    min-width: 200px;
    text-align: center;
    font-size: 2em;
  }

  .logo {
    font-size: 3em;
  }
}

@media (max-width: 480px) {
  .header {
    left: 1em;
    right: 1em;
    padding: 0 1.5em;
  }

  .hnav {
    left: 1em;
    right: 1em;
  }

  .logo {
    font-size: 2.5em;
  }

  .hnav ul {
    gap: 2em;
  }

  .header-btn {
    font-size: 1.8em;
  }
}
</style> 