<template>
  <div class="wrapper">
    <div class="marquee-wrapper">
      <div class="marquee-content">
        <div 
          v-for="n in 10" 
          :key="'top-' + n" 
          class="marquee-item"
        >
          MAESTRO ON CODE BY WELLO
        </div>
      </div>
    </div>

    <div class="c2">
      <div class="sec1">
        <div 
          v-for="project in projects" 
          :key="project.id"
          :class="['box', project.className]"
        >
          <div class="title-container">
            <h2>{{ project.title }}</h2>
          </div>
          <div class="project-desc-container">
            <p class="project-desc">{{ project.description }}</p>
            <a class="normalBtn">{{ project.type }}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="marquee-wrapper">
      <div class="marquee-content reverse">
        <div 
          v-for="n in 10" 
          :key="'bottom-' + n" 
          class="marquee-item"
        >
          MAESTRO ON CODE BY WELLO
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsSection',
  data() {
    return {
      projects: [
        {
          id: 1,
          title: 'BEATBOX',
          description: '8방향 리듬게임 프로젝트',
          type: '개발중',
          className: 'q',
        },
        {
          id: 2,
          title: 'MURING',
          description: '디스코드에서 롤 전적을',
          type: '서비스 종료',
          className: 'm',
        },
        {
          id: 3,
          title: '맞춤법',
          description: '간편하게 맞춤법 검사',
          type: '서비스 종료',
          className: 'c',
        }
      ],
      marqueeText: 'MAESTRO ON CODE BY WELLO'
    }
  }
}
</script>

<style scoped>
  .wrapper {
    width: 100%;
    background: linear-gradient(135deg, #000089, #1a1a8f);
  }

  .marquee-wrapper {
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 0.5em 0;
    width: 100%;
  }

  .marquee-content {
    display: flex;
    animation: marquee 20s linear infinite;
    white-space: nowrap;
  }

  .marquee-content.reverse {
    animation: marquee-reverse 20s linear infinite;
  }

  .marquee-item {
    flex-shrink: 0;
    padding: 0 0.5em;
    font-size: 2em;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  @keyframes marquee-reverse {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0);
    }
  }

  /* 호버 시 애니메이션 일시 정지 (선택사항) */
  .marquee-content:hover {
    animation-play-state: paused;
  }

  .c2 {
    width: 100%;
    padding: 0 0;
    display: flex;
    justify-content: center;
  }

  .c2 .sec1 {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    gap: 0;
    padding: 2em 2em;
    margin: 0 auto;
  }

  .c2 .box {
    flex: 1;
    min-height: 15em;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0;
    padding: 1.5em;
    justify-content: space-around;
    background: rgba(255, 255, 255, 0.98);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .c2 .box:first-child {
    border-top-left-radius: 3.5em;
    border-bottom-left-radius: 3.5em;
  }

  .c2 .box:last-child {
    border-top-right-radius: 3.5em;
    border-bottom-right-radius: 3.5em;
  }

  .c2 .box:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }

  .project-desc-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
  }

  .project-desc {
    font-size: 2.5em;
    margin: 0;
    margin-bottom: 0.2em;
    color: #333;
    font-weight: 500;
    line-height: 1.4;
  }
  
  .box h2 {
    font-size: 3em;
    margin: 0;
  }

  .normalBtn {
    color: #000089;
    border: 0.15em solid #000089;
    transition: background-color 0.3s, color 0.3s;
    font-size: 2.3em;
    font-weight: 500;
    padding: 0.3em 1em;
    border-radius: 1.5em;
    background: transparent;
    transition: all 0.2s ease;
  }

  .normalBtn:hover {
    background: #000089;
    color: #fff;
    transform: translateY(-1px);
  }
  
  .q, .m, .c { 
    background: #f7f3f0 !important;
  }
  
  .title-container {
    display: flex;
    align-items: center;
    margin : 0;
    font-size: 1em;
    color : #000089;
  }

  .title-container h2 {
    background: linear-gradient(45deg, #000089, #2020a0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    .c2 .sec1 {
      width: 90%;
    }
  }

  @media (max-width: 768px) {
    .c2 .sec1 {
      flex-direction: column;
      align-items: center;
      padding: 2em 1em;
      gap: 2em;
      width: 90%;
    }

    .marquee-item {
      font-size: 1.5em;
    }

    .c2 .box {
      border-radius: 0 !important;
      width: 100%;
      max-width: 400px;
    }

    .c2 .box:first-child,
    .c2 .box:last-child {
      border-radius: 0;
    }

    .project-desc {
      font-size: 1.6em;
      text-align: center;
    }

    .box h2 {
      font-size: 2em;
    }

    .normalBtn {
      font-size: 1.5em;
      padding: 0.3em 0.8em;
    }
  }

  @media (max-width: 480px) {
    .c2 .sec1 {
      gap: 1.5em;
      width: 95%;
    }

    .marquee-item {
      font-size: 1.2em;
    }

    .project-desc {
      font-size: 1.4em;
    }

    .box h2 {
      font-size: 1.8em;
    }

    .normalBtn {
      font-size: 1.3em;
    }
  }
</style>