<template>
  <section class="scroll-section" ref="scrollSection">
    <div class="content-wrapper" 
         :style="{
           position: wrapperPosition,
           top: `${wrapperTop}%`,
           transform: `translate(-50%, ${wrapperTranslateY}%)`
         }">
      <!-- 메인 텍스트 그룹 -->
      <div class="main-text-group"
           :style="{
             transform: `translateY(${mainGroupY}px)`
           }">
        <div class="main-text" 
             :style="{
               transform: `scale(${textScale})`,
               opacity: textOpacity,
               color: textColor
             }">
          C#M
        </div>
        <div class="sub-text"
             :style="{
               opacity: subTextOpacity,
               transform: `translateY(${subTextY}px)`
             }">
          코드 위의 마에스트로!
        </div>
      </div>

      <!-- 설명 텍스트 그룹 -->
      <div class="description-group"
           :style="{ 
             opacity: descriptionOpacity,
             transform: `translateY(${descriptionY}px)`
           }">
        <div class="desc-item" 
             v-for="(desc, index) in descriptions" 
             :key="index"
             :style="{ 
               transform: `translateX(${descTranslateX[index]}px)`,
               opacity: descOpacity[index]
             }">
          {{ desc }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'

export default {
  name: 'ScrollSection',
  setup() {
    const scrollSection = ref(null)
    const textScale = ref(6)
    const textOpacity = ref(0.3)
    const subTextOpacity = ref(0)
    const subTextY = ref(50)
    const descriptionOpacity = ref(0)
    const descriptionY = ref(100)
    const descTranslateX = ref(Array(4).fill(100))
    const descOpacity = ref(Array(4).fill(0))
    const scrollProgress = ref(0)
    const mainGroupY = ref(0)
    
    // 래퍼 위치 관련 상태
    const wrapperPosition = ref('fixed')
    const wrapperTop = ref(50)
    const wrapperTranslateY = ref(-50)

    const descriptions = [
      "코위마는",
      "2024년부터 시작한",
      "개쩌는 고등학생 개발팀입니다!",
      "코드 위의 마에스트로!"
    ]

    const textColor = computed(() => {
      const opacity = 0.3 + (textOpacity.value * 0.7)
      return `rgba(0, 0, 137, ${opacity})`
    })

    const handleScroll = () => {
      if (!scrollSection.value) return

      const rect = scrollSection.value.getBoundingClientRect()
      const totalHeight = scrollSection.value.offsetHeight
      const viewportHeight = window.innerHeight
      const scrollPosition = -rect.top
      scrollProgress.value = Math.max(0, Math.min(1, scrollPosition / (totalHeight - viewportHeight)))

      // 첫 번째 단계: C#M 텍스트 (0-20%)
      if (scrollProgress.value <= 0.2) {
        const p = scrollProgress.value / 0.2
        textScale.value = 6 - (p * 5)
        textOpacity.value = 0.3 + (p * 0.7)
        subTextOpacity.value = 0
        descriptionOpacity.value = 0
        mainGroupY.value = 0
        
        wrapperPosition.value = 'fixed'
        wrapperTop.value = 50
        wrapperTranslateY.value = -50
      } 
      // 두 번째 단계: CODE ABOVE MAESTRO (20-30%)
      else if (scrollProgress.value <= 0.3) {
        const p = (scrollProgress.value - 0.2) / 0.1
        textScale.value = 1
        textOpacity.value = 1
        subTextOpacity.value = p
        subTextY.value = 50 - (p * 50)
        mainGroupY.value = 0
      }
      // 세 번째 단계: 메인 텍스트 위로 이동 & 설명 텍스트 등장 (30-65%)
      else if (scrollProgress.value <= 0.65) {
        const p = (scrollProgress.value - 0.3) / 0.35
        mainGroupY.value = -150 * p  // 위로 이동하는 거리 조정
        
        descriptionOpacity.value = p
        descriptionY.value = 100 - (p * 100)
        
        // 설명 텍스트 더 빠른 등장
        descriptions.forEach((_, i) => {
          const delay = i * 0.08  // 더 빠른 순차 등장
          const adjustedP = Math.max(0, Math.min(1, (p - delay) * 4))  // 더 빠른 등장
          descTranslateX.value[i] = 100 - (adjustedP * 100)
          descOpacity.value[i] = adjustedP
        })
      }
      // 마지막 단계: 페이드아웃 (65-100%)
      else {
        const p = (scrollProgress.value - 0.65) / 0.35
        textOpacity.value = 1 - p
        subTextOpacity.value = 1 - p
        descriptionOpacity.value = 1 - p
        
        // 컨텐츠가 섹션을 벗어나지 않도록 위치 조정
        if (p > 0.8) {
          wrapperPosition.value = 'absolute'
          wrapperTop.value = 90
          wrapperTranslateY.value = -100
        }
      }

      // 시작 부분에서 컨텐츠가 섹션을 벗어나지 않도록
      if (scrollProgress.value < 0.1) {
        wrapperPosition.value = 'absolute'
        wrapperTop.value = 10
        wrapperTranslateY.value = 0
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
      handleScroll()
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      scrollSection,
      textScale,
      textOpacity,
      textColor,
      subTextOpacity,
      subTextY,
      descriptions,
      descriptionOpacity,
      descriptionY,
      descTranslateX,
      descOpacity,
      mainGroupY,
      wrapperPosition,
      wrapperTop,
      wrapperTranslateY
    }
  }
}
</script>

<style scoped>
.scroll-section {
  width: 100%;
  height: 400vh;
  background: #f7f3f0;
  position: relative;
  overflow: hidden;
}

.content-wrapper {
  width: 90%;
  max-width: 1400px;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.main-text-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.main-text {
  font-size: 15vw;
  font-weight: 900;
  text-align: center;
  white-space: nowrap;
  transform-origin: center center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1;
  margin-bottom: -0.2em;
}

.sub-text {
  font-size: 3vw;
  font-weight: 700;
  color: #000089;
  text-align: center;
  letter-spacing: 0.2em;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 0.5em;
}

.description-group {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.desc-item {
  font-size: 2.5em;
  color: #000089;
  text-align: center;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 600;
  line-height: 1.3;
}

@media (max-width: 768px) {
  .main-text {
    font-size: 20vw;
  }
  
  .sub-text {
    font-size: 5vw;
    margin-top: 0.3em;
  }

  .description-group {
    margin-top: 1.2em;
    gap: 0.8em;
  }

  .desc-item {
    font-size: 1.8em;
  }
}
</style> 