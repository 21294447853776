<template>
  <section class="us-section" id="us">
    <div class="content-wrapper">
      <div class="text-content">
        <div class="title-group">
          <h2>
            <div class="title-line">We are</div>
            <div class="title-line gradient-text">Students.</div>
          </h2>
          <div class="subtitle">C#M은 고등학생 팀이에요!!</div>
        </div>

        <div class="stats-container">
          <div class="stat-card">
            <div class="stat-icon">👥</div>
            <div class="stat-number">100<span class="accent">%</span></div>
            <div class="stat-label">학생</div>
          </div>
          <div class="stat-card">
            <div class="stat-icon">💫</div>
            <div class="stat-number">∞</div>
            <div class="stat-label">가능성</div>
          </div>
          <div class="stat-card">
            <div class="stat-icon">🚀</div>
            <div class="stat-number">0</div>
            <div class="stat-label">한계</div>
          </div>
        </div>

        <div class="description">
          학생인데 벌써 일하다니..<br>
          그래도 열심히 일합니다.
        </div>

        <button class="cta-button">
          <span>팀 소개 보기</span>
          <div class="arrow">→</div>
        </button>
      </div>

      <div class="visual-content">
        <div class="cube-container">
          <div class="cube">
            <div class="cube-face front">
              <span>MAESTRO</span>
            </div>
            <div class="cube-face back">
              <span>FUTURE</span>
            </div>
            <div class="cube-face right">
              <span>DREAM</span>
            </div>
            <div class="cube-face left">
              <span>CREATE</span>
            </div>
            <div class="cube-face top">
              <span>YOUNG</span>
            </div>
            <div class="cube-face bottom">
              <span>POWER</span>
            </div>
          </div>
          <div class="cube-shadow"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UsSection'
}
</script>

<style scoped>
.us-section {
  width: 100%;
  min-height: 100vh;
  background: #f7f3f0;
  color: #000089;
  padding: 8em 0;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.content-wrapper {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6em;
}

.title-group {
  margin-bottom: 4em;
}

.title-line {
  font-size: 5em;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 0.1em;
}

.gradient-text {
  background: linear-gradient(45deg, #000089, #000089);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 6em;
}

.subtitle {
  font-size: 3em;
  color: #333;
  margin-top: 1em;
}

.stats-container {
  display: flex;
  gap: 2em;
  margin-bottom: 4em;
}

.stat-card {
  background: rgba(0, 0, 137, 0.05);
  backdrop-filter: blur(10px);
  padding: 2em;
  border-radius: 20px;
  text-align: center;
  transition: all 0.3s ease;
  flex: 1;
  border: 2px solid rgba(0, 0, 137, 0.1);
}

.stat-card:hover {
  transform: translateY(-10px);
  background: rgba(0, 0, 137, 0.08);
  border-color: rgba(0, 0, 137, 0.2);
}

.stat-icon {
  font-size: 2em;
  margin-bottom: 0.5em;
}

.stat-number {
  color: #000089;
  -webkit-text-fill-color: #000089;
  font-size: 3.5em;
  font-weight: 900;
}

.stat-label {
  font-size: 1.4em;
  color: #888;
  margin-top: 0.5em;
}

.description {
  font-size: 2em;
  line-height: 1.6;
  color: #333;
  margin-bottom: 3em;
}

.cta-button {
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: 1.8em;
  color: #000089;
  background: transparent;
  border: 2px solid #000089;
  padding: 0.8em 1.6em;
  border-radius: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  background-color: #f7f3f07e;
}

.cta-button:hover {
  transform: translateY(-2px);
  background-color: #000089;
  color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 137, 0.2);
}

.arrow {
  transition: transform 0.3s ease;
}

.cta-button:hover .arrow {
  transform: translateX(10px);
  color: white;
}

.cube-container {
  position: relative;
  width: 40em;
  height: 40em;
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cube {
  width: 20em;
  height: 20em;
  position: relative;
  transform-style: preserve-3d;
  animation: rotate 20s infinite linear;
}

.cube-face {
  position: absolute;
  width: 20em;
  height: 20em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  font-weight: 700;
  color: white;
  background: #000089;
  border: 2px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  letter-spacing: 0.1em;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.2);
}

.cube-face:hover {
  background: #0000a0;
}

.front  { transform: rotateY(0deg) translateZ(10em); }
.back   { transform: rotateY(180deg) translateZ(10em); }
.right  { transform: rotateY(90deg) translateZ(10em); }
.left   { transform: rotateY(-90deg) translateZ(10em); }
.top    { transform: rotateX(90deg) translateZ(10em); }
.bottom { transform: rotateX(-90deg) translateZ(10em); }

.cube-shadow {
  position: absolute;
  width: 20em;
  height: 20em;
  background: rgba(0, 0, 137, 0.2);
  transform: rotateX(90deg) translateZ(-15em);
  filter: blur(20px);
  animation: shadow-pulse 4s infinite ease-in-out;
}

@keyframes rotate {
  from {
    transform: rotateX(-20deg) rotateY(0deg);
  }
  to {
    transform: rotateX(-20deg) rotateY(360deg);
  }
}

@keyframes shadow-pulse {
  0%, 100% {
    transform: rotateX(90deg) translateZ(-15em) scale(1);
    opacity: 0.6;
  }
  50% {
    transform: rotateX(90deg) translateZ(-15em) scale(1.2);
    opacity: 0.4;
  }
}

@media (max-width: 768px) {
  .cube-container {
    width: 30em;
    height: 30em;
    margin-top: 4em;
  }

  .cube, .cube-face {
    width: 15em;
    height: 15em;
  }

  .front, .back, .right, .left, .top, .bottom {
    transform: translateZ(7.5em);
  }

  .cube-shadow {
    width: 15em;
    height: 15em;
  }
}
</style> 