<template>
  <div 
    class="project-wrapper"
    @mouseenter="pauseAutoplay"
    @mouseleave="resumeAutoplay"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <div class="background-transition" :style="prevStyle"></div>
    <div class="background-transition" :style="currentStyle"></div>
    
    <section class="project-section" id="project">
      <div class="content-wrapper">
        <div class="text-content">
          <transition name="slide-fade" mode="out-in">
            <div :key="currentIndex">
              <div class="title-wrapper">
                <h2>{{ currentProject.title }}<span class="title-point">.</span></h2>
                <span class="subtitle">{{ currentProject.subtitle }}</span>
              </div>
              <p class="description" v-html="currentProject.description"></p>
              <div class="features">
                <div v-for="(feature, idx) in currentProject.features" :key="idx" class="feature">
                  <h3>{{ feature.title }}</h3>
                  <p>{{ feature.description }}</p>
                </div>
              </div>
              <div class="cta-wrapper">
                <a :href="currentProject.ctaLink" class="cta-button">{{ currentProject.ctaText }}</a>
              </div>
            </div>
          </transition>
        </div>

        <div class="visual-content">
          <transition name="fade" mode="out-in">
            <div class="logo-container" :key="'logo-' + currentIndex">
              <img 
                :src="currentProject.logoPath" 
                :alt="currentProject.title + ' 로고'" 
                class="project-logo"
              />
            </div>
          </transition>
        </div>
      </div>

      <!-- Desktop Navigation -->
      <div class="navigation-controls">
        <button @click="prevProject" class="nav-button prev-button desktop-only">
          <span class="nav-arrow">←</span>
          <span class="nav-text">이전</span>
        </button>
        <div class="project-indicators">
          <span 
            v-for="(_, index) in projects" 
            :key="index"
            :class="['indicator', { active: currentIndex === index }]"
            @click="goToProject(index)"
          ></span>
        </div>
        <button @click="nextProject" class="nav-button next-button desktop-only">
          <span class="nav-text">다음</span>
          <span class="nav-arrow">→</span>
        </button>
      </div>

      <!-- Mobile Swipe Hint -->
      <div class="swipe-hint mobile-only">
        <span class="swipe-text">스와이프하여 다음 프로젝트 보기</span>
      </div>

      <!-- Progress Bar -->
      <div class="progress-bar-container">
        <div class="progress-bar" :style="progressBarStyle"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ProjectSection',
  data() {
    return {
      currentIndex: 0,
      prevIndex: 0,
      autoplayInterval: null,
      progressWidth: 0,
      touchStartX: 0,
      touchEndX: 0,
      isPaused: false,
      projects: [
        {
          title: '비트박스',
          subtitle: '8방향 리듬 액션',
          description: '8방향 화살표로 만드는<br>새로운 리듬게임의 세계',
          features: [
            {
              title: '8방향 플레이',
              description: '상하좌우와 대각선 방향까지, 더욱 다채로운 리듬 액션을 즐겨보세요.'
            },
            {
              title: '감동적인 스토리',
              description: '음악과 함께 펼쳐지는 주인공의 여정을 따라가보세요.'
            }
          ],
          logoPath: '/beatlogo.svg',
          ctaLink: '#',
          ctaText: '개발중',
          style: {
            background: 'linear-gradient(135deg, #6B46C1, #805AD5)'
          }
        },
        {
          title: '뮤링',
          subtitle: '디스코드 봇',
          description: '당신의 롤 전적을<br>친구들과 공유하세요',
          features: [
            {
              title: '간편한 검색',
              description: '명령어 한번으로 롤 전적을 검색하세요.'
            },
            {
              title: '다양한 명령어',
              description: '관전, 랭킹 등 다양한 명령어를 사용하세요.'
            }
          ],
          logoPath: '/muring.png',
          ctaLink: '#',
          ctaText: '서비스 종료',
          style: {
            background: 'linear-gradient(135deg, #9F7AEA, #B794F4)'
          }
        },
        {
          title: '맞춤법',
          subtitle: '디스코드 봇',
          description: '정확한 맞춤법으로<br>메시지의 완성도를 높이세요',
          features: [
            {
              title: '긴 문장도 척척',
              description: '최대 2000자의 문장까지 검사합니다.'
            },
            {
              title: '빠르고 정확하게',
              description: '빠른 속도로 정확한 결과를 제공합니다.'
            }
          ],
          logoPath: '/cyw.png',
          ctaLink: '#',
          ctaText: '서비스 종료',
          style: {
            background: 'linear-gradient(135deg, #E53E3E, #FC8181)'
          }
        }
      ]
    }
  },
  computed: {
    currentProject() {
      return this.projects[this.currentIndex]
    },
    prevStyle() {
      return {
        ...this.projects[this.prevIndex].style,
        opacity: 0
      }
    },
    currentStyle() {
      return {
        ...this.currentProject.style,
        opacity: 1
      }
    },
    progressBarStyle() {
      return {
        width: `${this.progressWidth}%`
      }
    }
  },
  methods: {
    startAutoplay() {
      this.progressWidth = 0
      const duration = 5000
      const step = 16
      const increment = (step / duration) * 100

      this.autoplayInterval = setInterval(() => {
        if (!this.isPaused) {
          this.progressWidth += increment
          if (this.progressWidth >= 100) {
            this.nextProject()
            this.progressWidth = 0
          }
        }
      }, step)
    },
    stopAutoplay() {
      if (this.autoplayInterval) {
        clearInterval(this.autoplayInterval)
        this.autoplayInterval = null
      }
    },
    pauseAutoplay() {
      this.isPaused = true
    },
    resumeAutoplay() {
      this.isPaused = false
    },
    prevProject() {
      this.stopAutoplay()
      this.prevIndex = this.currentIndex
      this.currentIndex = (this.currentIndex - 1 + this.projects.length) % this.projects.length
      this.progressWidth = 0
      this.startAutoplay()
    },
    nextProject() {
      this.stopAutoplay()
      this.prevIndex = this.currentIndex
      this.currentIndex = (this.currentIndex + 1) % this.projects.length
      this.progressWidth = 0
      this.startAutoplay()
    },
    goToProject(index) {
      if (index === this.currentIndex) return
      this.stopAutoplay()
      this.prevIndex = this.currentIndex
      this.currentIndex = index
      this.progressWidth = 0
      this.startAutoplay()
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX
      this.pauseAutoplay()
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].clientX
      const swipeDistance = this.touchEndX - this.touchStartX
      
      if (Math.abs(swipeDistance) > 50) {
        if (swipeDistance > 0) {
          this.prevProject()
        } else {
          this.nextProject()
        }
      }
      this.resumeAutoplay()
    }
  },
  mounted() {
    this.startAutoplay()
  },
  beforeUnmount() {
    this.stopAutoplay()
  }
}
</script>

<style scoped>
.project-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-transition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.project-section {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  z-index: 1;
}

.content-wrapper {
  width: 83%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3em;
}

.text-content {
  flex: 1;
  max-width: 50%;
}

.title-wrapper {
  display: flex;
  align-items: baseline;
  gap: 0.5em;
}

.text-content h2 {
  font-family: 'Pretendard', sans-serif;
  font-size: 6em;
  font-weight: 900;
  margin: 0;
  display: flex;
  align-items: baseline;
}

.title-point {
  color: #F0771F;
  margin-left: 0.1em;
}

.subtitle {
  font-size: 2.4em;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 600;
}

.description {
  font-size: 4em;
  line-height: 1.2;
  margin: 0.3em 0 0.5em 0;
  font-weight: 600;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
}

.feature h3 {
  font-size: 3.5em;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0em;
  font-weight: 700;
}

.feature p {
  font-size: 2em;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.9);
}

.cta-button {
  display: inline-block;
  font-size: 2.3em;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1.2em;
  border: 0.15em solid rgba(255, 255, 255, 0.6);
  border-radius: 0.7em;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.8);
}

.visual-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-logo {
  width: 35em;
  height: auto;
  filter: drop-shadow(0 0 1em rgba(255, 255, 255, 0.3));
}

.navigation-controls {
  position: absolute;
  bottom: 2.5em;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 2em;
  z-index: 10;
}

.nav-button {
  font-size: 1.8em;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  border: 0.15em solid rgba(255, 255, 255, 0.6);
  padding: 0.5em 1.2em;
  border-radius: 0.7em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.8);
}

.project-indicators {
  display: flex;
  gap: 1em;
}

.indicator {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.indicator.active {
  background: rgba(255, 255, 255, 0.9);
  transform: scale(1.2);
}

.indicator:hover {
  background: rgba(255, 255, 255, 0.7);
}

.progress-bar-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
}

.progress-bar {
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  transition: width 0.016s linear;
}

.desktop-only {
  display: flex;
}

.mobile-only {
  display: none;
}

.swipe-hint {
  position: absolute;
  bottom: 4em;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.6);
  font-size: 1.4em;
  animation: fadeInOut 2s infinite;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.4; }
  50% { opacity: 0.8; }
}

/* Transitions */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column-reverse;
    width: 90%;
    gap: 2em;
    padding: 2em 0;
  }

  .text-content {
    max-width: 100%;
    text-align: center;
  }

  .title-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 0.3em;
  }

  .text-content h2 {
    font-size: 4.5em;
  }

  .subtitle {
    font-size: 2em;
  }

  .description {
    font-size: 3em;
  }

  .feature h3 {
    font-size: 2em;
  }

  .feature p {
    font-size: 1.6em;
  }

  .project-logo {
    width: 25em;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }

  .navigation-controls {
    bottom: 2em;
  }

  .project-indicators {
    gap: 1.2em;
  }

  .indicator {
    width: 1em;
    height: 1em;
    background: rgba(255, 255, 255, 0.4);
  }

  .indicator.active {
    background: rgba(255, 255, 255, 1);
    transform: scale(1.3);
  }
}

@media (max-width: 480px) {
  .text-content h2 {
    font-size: 3.5em;
  }

  .subtitle {
    font-size: 1.8em;
  }

  .description {
    font-size: 2.5em;
  }

  .feature h3 {
    font-size: 1.8em;
  }

  .feature p {
    font-size: 1.4em;
  }

  .project-logo {
    width: 20em;
  }

  .navigation-controls {
    bottom: 1.5em;
  }
}
</style> 