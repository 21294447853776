'<template>
  <div id="app">
    <SiteHeader />
    <HeroSection />
    <ProjectsSection />
    <UsSection />
    <ScrollSection />
    <ProjectSection />
    <FooterSection />
  </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import HeroSection from './components/HeroSection.vue'
import ProjectsSection from './components/ProjectsSection.vue'
import UsSection from './components/UsSection.vue'
import FooterSection from './components/FooterSection.vue'
import ScrollSection from './components/ScrollSection.vue'
import ProjectSection from './components/ProjectSection.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    HeroSection,
    ScrollSection,
    ProjectsSection,
    UsSection,
    FooterSection,
    ProjectSection
  }
}
</script>

<style>
@font-face {
    font-family: 'bitbit';
    src: url('https://cdn.df.nexon.com/img/common/font/DNFBitBit-Regular.woff'),
         url('//cdn.df.nexon.com/img/common/font/DNFBitBit-Regular.woff2');
  }
  @font-face {
    font-family: 'Pretendard';
    src: url(../src/assets/fonts/PretendardVariable.ttf);
  }
  @font-face {
    font-family: 'Neurimbo';
    src: url(../src/assets/fonts/neurimboGothicRegular.otf);
  }
  * {
    font-family: 'Pretendard';
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    font-size: 8px;
    width: 100%;
    overflow-x: hidden;
  }
  
  a {
    text-decoration: none;
    outline: none;
  }
  
  li {
    list-style: none;
  }
  .header {
    background-color: #f7f3f07e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 15em;
    right: 15em;
    top: 2em;
    height: 8em;
    border-radius: 3.5em;
    backdrop-filter: blur(5px);
    z-index: 1000;
  }
  
  .header .hnav {
    margin-right: 5em;
    display: flex;
    justify-content: center;
  }
  
  .header nav ul {
    display: flex;
    gap: 1em;
  }
  
 


  
  .logo {
    margin-left: 2em;
    font-size: 4em;
    color: #000089;
    font-family: 'Neurimbo', sans-serif;
  }

  
  /* 반응형 디자인 */
  @media (max-width: 768px) {
    .header {
      left: 2em;
      right: 2em;
    }
  
    .c2 .sec1 {
      width: 90%;
      flex-direction: column;
    }
  
    .c2 .box {
      width: 80%;
    }
  
    .c1 .title {
      font-size: 10em;
    }
  }


  /* 태블릿 */
  @media (max-width: 1024px) {
    html {
      font-size: 55%; /* 기본 8.8px */
    }
  }

  /* 모바일 */
  @media (max-width: 768px) {
    html {
      font-size: 50%; /* 기본 8px */
    }
  }

  /* 작은 모바일 */
  @media (max-width: 480px) {
    html {
      font-size: 45%; /* 기본 7.2px */
    }
  }

  #app {
    width: 100%;
    overflow-x: hidden;
  }

  .content-wrapper {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4em;
    padding: 0 2em;
    box-sizing: border-box;
  }

  .hexagon-grid {
    position: relative;
    width: 35em;
    height: 35em;
  }

  @media (max-width: 1024px) {
    .content-wrapper {
      gap: 2em;
    }
    
    .hexagon-grid {
      width: 30em;
      height: 30em;
    }
  }

  @media (max-width: 768px) {
    .content-wrapper {
      flex-direction: column;
      padding: 0 1em;
    }
  }

  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }

  .project-logo {
    width: 35em;
    height: auto;
    filter: drop-shadow(0 0 1em rgba(255, 255, 255, 0.3));
  }

  .bbox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
  }

  .bbox-image {
    width: 25em;
    height: auto;
    transition: transform 0.5s ease;
  }

  .rotation-controls {
    display: flex;
    gap: 1em;
    justify-content: center;
  }

  .rotate-btn {
    font-size: 2em;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.1);
    border: 0.15em solid rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    width: 2em;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 0;
  }

  .rotate-btn:hover {
    background: rgba(255, 255, 255, 0.2);
    border-color: rgba(255, 255, 255, 0.8);
  }

  .rotate-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    .project-logo {
      width: 25em;
    }

    .bbox-image {
      width: 20em;
    }

    .rotate-btn {
      font-size: 1.8em;
    }
  }

  @media (max-width: 480px) {
    .project-logo {
      width: 20em;
    }

    .bbox-image {
      width: 16em;
    }

    .rotate-btn {
      font-size: 1.6em;
    }
  }
</style>